.image-swiper {
  position: relative;
  width: 100%;
  overflow: hidden;

  .slides {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }

  .slide {
    min-width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
  }

  .controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);

    .arrow {
      background: none;
      border: none;
      font-size: 2rem;
      color: white;
      cursor: pointer;
      z-index: 10;
      padding: 0 10px;

      .charm--chevron-right {
        display: inline-block;
        width: 1em;
        height: 1em;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.4' d='M5.75 12.25L10.25 8l-4.5-4.25'/%3E%3C/svg%3E");
      }

      .charm--chevron-left {
        display: inline-block;
        width: 1em;
        height: 1em;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.4' d='M10.25 3.75L5.75 8l4.5 4.25'/%3E%3C/svg%3E");
      }
    }
  }

  .dots {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    .dot {
      height: 10px;
      width: 10px;
      margin: 0 5px;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;

      &.active {
        background-color: white;
      }
    }
  }
}
