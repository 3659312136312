@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Footer {
  min-height: 40vh;
  padding: 20px;
  box-sizing: border-box;
  background-color: black;
  display: flex;
  justify-content: center;

  @include breakpoint-for(desktop-small) {
    padding: 40px;
  }

  .container-footer {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    gap: 20px;

    @include breakpoint-for(desktop-small) {
      gap: 0;
    }

    .top {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
      }
    }

    .bottom {
      margin-top: 60px;
      border-style: solid;
      border-width: 1px 0px 0px 0px;
      border-color: #242424;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
      }

      p {
        color: white;
        font-family: $heebo_font;
        font-size: 16px;
        font-weight: 300;
        margin: 10px 0;
        text-align: left;
        text-decoration: unset;
      }
    }

    .two-columns {
      display: flex;
      gap: 5%;
      width: 100%;
      flex-direction: column;
      gap: 20px;

      @include breakpoint-for(desktop-small) {
        flex-direction: row;
      }
    }
    .footer-logo {
      display: flex;
      flex-direction: column;

      .logo {
        max-width: 300px;
        max-height: 151px;
        width: 60%;
      }

      ul {
        width: 100%;
        padding: 0;
        margin: 0;

        @include breakpoint-for(desktop-small) {
          // width: 50%;
          margin: 15px 0;
        }

        li {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          margin: 10px 0;
          font-family: $heebo_font;
          font-size: 15px;

          .ph--map-pin-fill {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='%230AD8A5' d='M128 16a88.1 88.1 0 0 0-88 88c0 75.3 80 132.17 83.41 134.55a8 8 0 0 0 9.18 0C136 236.17 216 179.3 216 104a88.1 88.1 0 0 0-88-88m0 56a32 32 0 1 1-32 32a32 32 0 0 1 32-32'/%3E%3C/svg%3E");
          }

          .ic--baseline-email {
            display: inline-block;
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%230AD8A5' d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 4l-8 5l-8-5V6l8 5l8-5z'/%3E%3C/svg%3E");
          }
          a {
            color: white;
            font-family: $heebo_font;
            font-size: 15px;
            display: flex;
            text-align: left;
            gap: 10px;
            text-decoration: unset;

            &:hover {
              color: #0ad8a5;
            }
          }
        }
      }

      .buttons-social {
        display: flex;
        gap: 5px;

        .button-social {
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          background-color: #818a91;
          border-radius: 5px;

          &:hover {
            background-color: #262626;
          }
          .text-hidden-accesible {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
          }

          .brandico--facebook-rect {
            display: inline-block;
            width: 50%;
            height: 50%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath fill='white' d='M182.594 0C81.445 0 0 81.445 0 182.594v634.813c0 101.149 81.445 182.594 182.594 182.594h344.063V609.063H423.282v-140.75h103.375v-120.25c0-94.475 61.079-181.219 201.781-181.219c56.968 0 99.094 5.469 99.094 5.469l-3.313 131.438s-42.963-.406-89.844-.406c-50.739 0-58.875 23.378-58.875 62.188v102.781h152.75l-6.656 140.75H675.5v390.938h141.906c101.149 0 182.594-81.445 182.594-182.594V182.595C1000 81.446 918.555.001 817.406.001H182.593z'/%3E%3C/svg%3E");
          }

          .mdi--instagram {
            display: inline-block;
            width: 60%;
            height: 60%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3'/%3E%3C/svg%3E");
          }

          .mdi--tiktok {
            display: inline-block;
            width: 60%;
            height: 60%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M16.6 5.82s.51.5 0 0A4.28 4.28 0 0 1 15.54 3h-3.09v12.4a2.59 2.59 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48'/%3E%3C/svg%3E");
          }

          .mdi--youtube {
            display: inline-block;
            width: 60%;
            height: 60%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='m10 15l5.19-3L10 9zm11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73'/%3E%3C/svg%3E");
          }
        }
      }
    }

    .footer-links {
      display: flex;
      flex-direction: column;

      .title-footer {
        color: #0ad8a5;
        font-family: $oswald_font;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        text-align: left;
        gap: 10px;
        margin-bottom: 20px;
        text-decoration: unset;
      }

      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: calc(20px / 2);

        li {
          a {
            align-items: center;
            display: flex;
            gap: 5px;
            text-decoration: unset;

            &:hover {
              .text-click {
                color: #0ad8a5;
              }
            }

            .text-click {
              align-items: center;
              color: white;
              display: flex;
              font-family: $heebo_font;
              font-size: 16px;
              font-weight: 300;
              letter-spacing: 1.3px;
              text-decoration: unset;
            }
            .fa--angle-right {
              display: inline-block;
              width: 15px;
              height: 10px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 608 1280'%3E%3Cg transform='translate(608 0) scale(-1 1)'%3E%3Cpath fill='white' d='M595 288q0 13-10 23L192 704l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L23 727q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23'/%3E%3C/g%3E%3C/svg%3E");
            }
          }
        }
      }
    }

    .footer-newsletter {
      max-width: 100%;

      @include breakpoint-for(desktop-small) {
        max-width: 32%;
      }

      .title-footer {
        color: #0ad8a5;
        font-family: $oswald_font;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        text-align: left;
        gap: 10px;
        margin-bottom: 20px;
        text-decoration: unset;
      }

      p {
        color: #f7f7f7;
        font-family: $heebo_font;
        font-size: 16px;
        font-weight: 300;
        text-align: left;
        text-decoration: unset;
      }

      form {
        margin-top: 50px;

        .message {
          background-color: #75ffd8;
          padding: 10px 15px;
          margin-bottom: 10px;
          border-radius: 5px;
          border: 1px solid #007f5b;
          color: black;
          font-family: $heebo_font;
          font-size: 16px;
          font-weight: 300;
          text-align: left;
        }
        .input-container {
          // flex-wrap: wrap;
          display: flex;
          // -ms-flex-wrap: wrap;

          .email-input {
            width: 100%;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
            -ms-flex: 0 0 75%;
            flex: 0 0 75%;
            margin-right: 0px;
            border-radius: 5px 0px 0px 5px;
            padding: 14px 20px 14px 20px;
            border-style: solid;
            border-width: 0;
            box-sizing: border-box;
            outline: unset;
            font-family: $heebo_font;
          }

          .submit-button {
            padding: 10px 20px;
            border: none;
            background-color: #0dd7b4;
            color: white;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            align-self: center;
            -webkit-box-flex: 81px;
            -ms-flex: 0 0 96px;
            flex: 0 0 96px;
            border-radius: 0px 5px 5px 0px;
            padding: 19px 10px 19px 10px;
            box-sizing: border-box;
            font-family: $oswald_font;
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 1.3px;
            color: #ffffff;
            text-transform: uppercase;

            &:hover {
              background-color: #0bb296;
            }
          }
        }
      }
    }
  }
}
