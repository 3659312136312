@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.HomeMarket {
  padding: 80px 0px 100px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 22px;
    font-family: $oswald_font;
    font-weight: 600;
    line-height: 1.2em;
    margin: 0 0 20px 0;

    @include breakpoint-for(tablet) {
      font-size: 32px;
    }
  }

  .container-markets {
    max-width: 1170px;
    margin-top: 40px;
    width: 100%;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
    flex-wrap: wrap;

    .container-line {
      display: flex;
      flex-direction: column;
      gap: 40px;
      justify-content: space-between;
      margin-top: 40px;
      width: 100%;

      @include breakpoint-for(tablet) {
        flex-direction: row;
        font-size: 32px;
        gap: 0;
      }
    }

    .market {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin: 0px 20px 0px 0px;

      @include breakpoint-for(tablet) {
        align-items: flex-start;
        width: 33%;
        max-width: 380px;
      }

      .header {
        padding: 0px 0px 0px 0px;
        margin-bottom: 10px;
        width: 45px;

        img {
          height: auto;
          max-width: 100%;
          border: none;
          border-radius: 0;
          box-shadow: none;
        }
      }

      .market-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint-for(tablet) {
          align-items: flex-start;
        }

        h3 {
          font-family: $oswald_font;
          font-size: 24px;
          font-weight: 500;
          line-height: 1.3em;
          margin: 0px 0px 10px 10px;
          padding: 0px;
        }

        p {
          font-family: $heebo_font;
          margin: 0px 0px 0px 10px;
          font-size: 16px;
          font-weight: 300;
          color: #7a7a7a;
          text-align: left;
        }
      }
    }

    .button-market {
      margin: 70px 0 0 0;
      width: 100%;

      a {
        background-color: #0dd7b4;
        border-radius: 5px 5px 5px 5px;
        border-style: solid;
        box-shadow: none;
        color: #ffffff;
        font-family: $oswald_font;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 1.3px;
        line-height: 1em;
        border: none;
        padding: 20px 40px 20px 40px;
        text-decoration: none;
        text-transform: uppercase;
        transition: all 0.4s ease;

        &:hover {
          color: black;
        }
      }
    }
  }
}
