@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.LocationsHome {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;
  .container-locations-title {
    padding: 50px 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    max-width: 1200px;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;

      h1 {
        color: black;
        font-family: $oswald_font;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: absolute;
        text-align: center;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 35px;
        }
      }

      span {
        color: #04020230; // COR PADRÃO DO TITULO EM BRANCO
        font-family: $oswald_font;
        font-size: 60px;
        font-weight: bold;
        letter-spacing: 1.5px;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 80px;
        }
      }
    }

    p {
      color: #04020250; // COR SECUNDÁRIO DO TITULO EM BRANCO
      font-family: $heebo_font;
      font-size: 15px;
      line-height: 1.5;
      margin: 0;
      text-align: left;
      z-index: 10;

      @include breakpoint-for(desktop-small) {
        font-size: 20px;
      }
    }
  }

  .carousel-container {
    width: 100%;
    max-width: 1200px;
    margin: 50px 0;
    
    .slick-prev:before, .slick-next:before {
      color: rgba(0, 0, 0, 0.606);
    }
  

    .client-slider.item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;

      .image-list {
        width: 100%;
      }

      .content-image img {
        width: 100%;
        height: auto;
      }
    }
  }

  .btn-artists {
    background-color: #0dd7b4;
    border-radius: 5px;
    border-style: solid;
    border-width: 0;
    color: #FFFFFF;
    cursor: pointer;
    font-family: $oswald_font;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-decoration: unset;
    line-height: 1.5;
    margin-top: 50px;
    padding: 20px 40px;
    text-align: center;
    text-transform: uppercase;

    &:hover {
      color: black;
    }
  }
}
