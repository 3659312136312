@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.details-summary {
  border-radius: 5px;
  margin: 10px 0;
  padding: 10px 0;
  box-sizing: border-box;
  width: 100%;
  transition: background-color 0.3s ease;

  &.open {
    background-color: white;
  }

  summary {
    align-items: center;
    border-radius: 5px;
    box-shadow: -1.216px 6.894px 15px 0 rgba(37, 117, 252, 0.2);
    color: #575a5f;
    cursor: pointer;
    display: flex;
    font-family: $oswald_font;
    font-weight: 700;
    justify-content: space-between;
    list-style: none;
    outline: none;
    padding: 14px 30px;
    text-transform: uppercase;
    transition: color 0.3s ease;
    user-select: none;

    &.open {
      color: #0ad8a5;

      .arrow {
        width: 4px;
        height: 4px;
        border: solid #0ad8a5;
        border-width: 0 2px 2px 0;
      }
    }

    .arrow {
      display: inline-block;
      width: 4px;
      height: 4px;
      border: solid black;
      border-width: 0 2px 2px 0;
      padding: 2px;
      transition: transform 0.3s ease;

      &.down {
        transform: rotate(45deg);
      }

      &.up {
        transform: rotate(-135deg);
      }
    }
  }

  .content {
    margin-top: 10px;
  }
}
