@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Artist {
  height: 400px;
  background-color: white;
  width: 300px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  @include breakpoint-for(desktop-small) {
    width: 360px;
    height: 505px;
  }

  .infos {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: white;
    padding: 10px;
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.5);

    .title {
      color: white;
      font-family: "Oswald", sans-serif;
      font-size: 30px;
      margin-left: 10px;
      transform: translateY(200%);
      transition: transform 0.4s ease-in-out;
    }

    .icons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      transform: translateX(200%);
      transition: transform 0.4s ease-in-out;

      button {
        align-items: center;
        background-color: #0dd7b4;
        border-radius: 50%;
        border: unset;
        cursor: pointer;
        display: flex;
        height: 30px;
        justify-content: center;
        padding: 0;
        width: 30px;
        margin: 10px 0;
        position: relative;
        overflow: hidden;

        &:hover span {
          animation: slideAround 0.3s forwards;
        }

        @keyframes slideAround {
          0% {
            transform: translateY(0%);
          }
          50% {
            transform: translateY(150%);
            visibility: hidden;
          }
          80% {
            transform: translateY(-200%);
            visibility: hidden;
          }
          100% {
            transform: translateY(0%);
          }
        }

        span {
          transition: transform 0.4s ease-in-out;
          position: absolute;
        }

        .mdi--instagram {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3'/%3E%3C/svg%3E");
        }

        .mdi--tiktok {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M16.6 5.82s.51.5 0 0A4.28 4.28 0 0 1 15.54 3h-3.09v12.4a2.59 2.59 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48'/%3E%3C/svg%3E");
        }

        .ri--facebook-fill {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4z'/%3E%3C/svg%3E");
        }

        .twitter {
          display: inline-block;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='white' d='M23.64 4.56a9.73 9.73 0 0 1-2.82.77A4.92 4.92 0 0 0 23 2.89a9.81 9.81 0 0 1-3.1 1.18A4.9 4.9 0 0 0 16.62 2a4.92 4.92 0 0 0-4.92 4.92c0 .38.04.75.13 1.1A13.93 13.93 0 0 1 1.67 3.15a4.9 4.9 0 0 0 1.52 6.57 4.85 4.85 0 0 1-2.23-.62v.06A4.92 4.92 0 0 0 4.9 14a4.94 4.94 0 0 1-2.22.08A4.93 4.93 0 0 0 6.92 16a9.83 9.83 0 0 1-6.09 2.1c-.4 0-.79-.02-1.17-.07a13.88 13.88 0 0 0 7.52 2.2c9.05 0 14-7.5 14-14 0-.21 0-.42-.01-.63A10.02 10.02 0 0 0 24 4.57'/%3E%3C/svg%3E");
        }
      }

      p {
        writing-mode: vertical-rl;
        color: white;
        font-family: "Oswald", sans-serif;
        font-size: 20px;
        margin: 0;
      }

      .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }
  }

  &:hover {
    .infos {
      opacity: 1;

      .title {
        transform: translateY(60%);
      }

      .icons {
        transform: translateX(0%);
      }
    }
  }
}
