@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.Artists {
  padding-bottom: 40px;
  .background-artists {
    background-position: 50% 45%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }

    .container-title {
      padding-top: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 80%;

      .title {
        align-items: center;
        display: flex;
        justify-content: center;

        h1 {
          color: white;
          font-family: $oswald_font;
          font-size: 25px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: absolute;
          text-align: center;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 35px;
          }
        }

        span {
          color: #ffffff38;
          font-family: $oswald_font;
          font-size: 60px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 80px;
          }
        }
      }

      p {
        color: white;
        font-family: $heebo_font;
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
        text-align: center;
        z-index: 10;
      }
    }
  }

  .container-artists {
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 50px;

    .box-artists {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      max-width: 1200px;
    }

    .card-artists {
      width: 33%;
    }
  }

  .container-videos-artists {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;

    .container-videos-title {
      padding: 50px 15px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      .title {
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
          color: black;
          font-family: $oswald_font;
          font-size: 25px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: absolute;
          text-align: center;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 35px;
          }
        }

        span {
          color: #04020230; // COR PADRÃO DO TITULO EM BRANCO
          font-family: $oswald_font;
          font-size: 60px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 80px;
          }
        }
      }

      p {
        color: #04020250; // COR SECUNDÁRIO DO TITULO EM BRANCO
        font-family: $heebo_font;
        font-size: 15px;
        line-height: 1.5;
        margin: 0;
        text-align: center;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 20px;
        }
      }
    }

    .box-videos {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1000px;
      width: 100%;
      gap: 50px;
      margin-top: 100px;
    }

    .box {
      background: #000;
      height: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
      position: relative;
      width: 100%;

      iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  .container-productions {
    .title {
      color: black;
      font-family: $oswald_font;
      font-size: 25px;
      font-weight: bold;
      line-height: 1.5;
      margin: 20px 0;
      padding: 0;
      position: relative;
      text-align: center;
      z-index: 10;

      @include breakpoint-for(desktop-small) {
        font-size: 35px;
      }
    }
    .carousel-container {
      width: 100%;
      max-width: 1200px;
      margin: auto;

      .client-slider.item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        box-sizing: border-box;

        .image-list {
          width: 100%;
        }

        .content-image img {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .container-counts {
    display: flex;
    justify-content: center;
    margin-top: 50px;

    .counter-container {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
      max-width: 1200px;
      width: 100%;

      @include breakpoint-for(desktop-small) {
        align-items: flex-start;
        gap: 20px;
      }
    }

    .counter-column {
      margin-bottom: 20px;
    }

    .counter-widget {
      text-align: center;
    }

    .counter-number-wrapper {
      display: flex;
      font-family: $heebo_font;
      font-size: 39px;
      color: #7a7a7a;
      font-weight: 600;
      line-height: 1;

      @include breakpoint-for(desktop-small) {
        font-size: 69px;
      }
    }

    .counter-prefix,
    .counter-suffix {
      flex-grow: 1;
      white-space: pre-wrap;
    }

    .counter-title {
      text-align: center;
      font-family: $heebo_font;
      font-size: 15px;
      color: #7a7a7a;
      font-weight: 400;
      line-height: 2.5;

      @include breakpoint-for(desktop-small) {
        font-size: 19px;
      }
    }
  }
}
