@import './fonts';
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100..900&display=swap');

$primary_font_book: "Museo Regular";
$primary_font_demi: "Museo Bold";

$heebo_font: 'Heebo', sans-serif;
$oswald_font: 'Oswald', sans-serif;

$breakpoints: (
  "mobile-medium": 400px,
  "mobile-larger": 576px,
  "tablet": 768px,
  "tablet-large": 992px,
  "desktop-small": 1024px,
  "desktop-medium": 1440px,
  "desktop-large": 1600px,
  "desktop-extra-large": 1800px
);