@import "../../../assets/scss/variables";
@import "../../../assets/scss/mixins";

.Communities {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 50px 0;

  .background-communities {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    width: 100%;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 1;
    }

    .container-title {
      padding-top: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      height: 80%;

      .title {
        align-items: center;
        display: flex;
        justify-content: center;

        h1 {
          color: white;
          font-family: $oswald_font;
          font-size: 25px;
          font-weight: bold;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: absolute;
          text-align: center;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 35px;
          }
        }

        span {
          color: #ffffff38;
          font-family: $oswald_font;
          font-size: 60px;
          font-weight: bold;
          letter-spacing: 1.5px;
          line-height: 1.5;
          margin: 0;
          padding: 0;
          position: relative;
          text-align: center;
          text-transform: uppercase;
          z-index: 10;

          @include breakpoint-for(desktop-small) {
            font-size: 80px;
          }
        }
      }

      p {
        color: white;
        font-family: $heebo_font;
        font-size: 20px;
        line-height: 1.5;
        margin: 0;
        text-align: center;
        z-index: 10;
      }
    }
  }

  .container-contact {
    padding: 50px 15px;
    box-sizing: border-box;

    .header-contact {
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        font-family: $oswald_font;
        font-size: 25px;
        font-weight: bold;
        line-height: 1.5;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 35px;
        }
      }

      p {
        font-family: $heebo_font;
        font-size: 15px;
        color: #7a7a7a;
        line-height: 1.5;
        margin: 10px 0;
        text-align: left;
        z-index: 10;

        @include breakpoint-for(desktop-small) {
          font-size: 18px;
        }
      }

      .button-external {
        margin: 70px 0 0 0;
        width: 100%;
        height: 80px;

        a {
          background-color: #0dd7b4;
          border-radius: 5px 5px 5px 5px;
          border-style: solid;
          box-shadow: none;
          color: #ffffff;
          font-family: $oswald_font;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 1.3px;
          line-height: 1em;
          border: none;
          padding: 20px 40px 20px 40px;
          text-decoration: none;
          text-transform: uppercase;
          transition: all 0.4s ease;

          &:hover {
            color: black;
          }
        }
      }
    }
  }

  .carousel-container {
    width: 100%;
    max-width: 1200px;
    margin: 20px 0;

    .slick-prev:before,
    .slick-next:before {
      color: rgba(0, 0, 0, 0.606);
    }

    h2 {
      font-family: $oswald_font;
      font-size: 25px;
      font-weight: bold;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      position: relative;
      text-align: center;
      z-index: 10;

      @include breakpoint-for(desktop-small) {
        font-size: 35px;
        margin-bottom: 20px;
      }
    }

    .client-slider.item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      box-sizing: border-box;

      .image-list {
        width: 100%;
      }

      .content-image img {
        width: 100%;
        height: auto;

        @include breakpoint-for(desktop-small) {
          min-height: 249px;
        }
      }
    }
  }

  .bottom-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 17px;
    margin-top: 30px;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 20px 0;
    padding: 0 10px;
    box-sizing: border-box;

    @include breakpoint-for(desktop-small) {
      margin-top: 60px;
    }

    h2 {
      font-family: $oswald_font;
      font-size: 25px;
      font-weight: bold;
      line-height: 1.5;
      margin: 0;
      padding: 0;
      position: relative;
      text-align: center;
      z-index: 10;

      @include breakpoint-for(desktop-small) {
        font-size: 35px;
      }
    }

    div {
      gap: 15px;
      display: flex;

      a {
        color: black;
        font-family: $heebo_font;
        font-size: pxToRem(16px);
        outline: none;
        text-align: left;
        text-decoration: unset;
      }
    }
  }
}
